import * as React from "react";
import { Link } from "gatsby";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
};

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <title>Not found</title>
      <div className="container-fluid" style={{ display: "flex" }}>
        <div
          className="col-lg-8 col-md-8 col-sm-8 col-xs-12"
          style={{ width: "60%" }}
        >
          <img
            src="https://i.imgur.com/A040Lxr.png"
            style={{ width: "100%" }}
            alt="lost astronaut"
          />
        </div>
        <div
          className="col-lg-4 col-md-4 col-sm-4 col-xs-12"
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingLeft: "50px",
          }}
        >
          <h1 style={headingStyles}>Page not found</h1>
          <p style={paragraphStyles}>
            Sorry{" "}
            <span role="img" aria-label="Pensive emoji">
              😔
            </span>{" "}
            we couldn’t find what you were looking for.
            <br />
            {process.env.NODE_ENV === "development" ? (
              <>
                <br />
                Try creating a page in{" "}
                <code style={codeStyles}>src/pages/</code>.
                <br />
              </>
            ) : null}
            <br />
            <Link to="/">Go home</Link>.
          </p>
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;
